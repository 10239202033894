<template>
  <div>
    <v-hover v-slot="{ isHovering, props }">
      <v-card
        @click.exact="sendBack"
        @click.meta.exact="openInTab"
        @click.ctrl.exact="openInTab"
        color="white"
        width="350"
        elevation="0"
        :id="heatmap.uid"
        v-bind="props"
        v-if="heatmap"
      >
        <v-img
          height="200"
          cover
          :aspect-ratio="16 / 9"
          :src="
            heatmap.thumbnail && heatmap.status === 'done'
              ? heatmap.thumbnail
              : heatmap.imageURL
          "
          :key="heatmap.thumbnail"
        >
          <v-expand-transition>
            <div
              class="checkbox"
              v-if="
                heatmap.status === 'done' &&
                (isHovering || compare.includes(heatmap.uid))
              "
            >
              <v-checkbox
                v-if="heatmap.status === 'done'"
                class="ma-0"
                v-model="check"
                hide-details=""
                color="warning"
                @click.stop="checkbox"
              ></v-checkbox>
            </div>
          </v-expand-transition>

          <v-alert
            v-if="heatmap.status === 'error' || heatmap.status === 'failed'"
            style="position: absolute; bottom: 0px; width: 100%"
            type="error"
            >Error generating heatmap</v-alert
          >

          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-6"
                v-if="heatmap.status === 'requested'"
                style="position: absolute; bottom: 10px"
                depressed
                flat=""
                v-bind="props"
              >
                Processing
              </v-btn>
            </template>

            <span
              >Estimated processing time: Images: 1 minute, Videos: 10 minutes
              per minute of video.</span
            >
          </v-tooltip>
          <v-btn
            class="ml-6"
            color="smoke"
            v-if="heatmap.status === 'done' && heatmap.type === 'video'"
            position="absolute"
            variant="tonal"
            depressed=""
            style="bottom: 20px"
          >
            <v-icon>mdi-video</v-icon>
          </v-btn>

          <v-expand-transition v-if="heatmap.heatmap">
            <div
              v-if="isHovering"
              class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
              style="height: 100%"
            >
              <v-img
                cover
                :aspect-ratio="16 / 9"
                height="200"
                :src="heatmap.heatmap"
              >
              </v-img>
            </div>
          </v-expand-transition>
          <v-progress-linear
            v-if="
              heatmap.status === 'requested' || heatmap.status === 'processed'
            "
            :active="true"
            indeterminate
            absolute
            location="bottom"
            color="primary accent"
          ></v-progress-linear>
        </v-img>

        <v-card-text class="px-6 pt-6" style="position: relative">
          <!-- <v-btn absolute color="primary" class="white--text" fab large right top>
          <v-icon>mdi-eye</v-icon>
        </v-btn> -->
          <h6
            class="text-h6 font-weight-medium text-warning mb-0 text-truncate"
          >
            {{ heatmap.title ? heatmap.title : "" }}
          </h6>
          <div>
            <p class="text-truncate" style="font-size: 10pt; margin-bottom: 0">
              <span style="font-weight: 500">{{ heatmap.application }}</span> •
              {{ heatmap.requested_by_name }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0 px-6 pb-3">
          <v-btn
            v-if="
              (heatmap.clarity_score_percentile === 0 ||
                heatmap.clarity_score_percentile > 0) &&
              heatmap.application != 'Other'
            "
            style="text-transform: none"
            class="bg-accent white--text font-weight-bold"
            ><span class="font-weight-regular" style="font-size: 10pt"
              >Clarity: </span
            >{{ heatmap.clarity_score_percentile }}</v-btn
          >
          <v-btn
            v-if="
              heatmap.status === 'done' &&
              (attentionScore === 0 || attentionScore > 0)
            "
            style="text-transform: none"
            elevation="0"
            class="bg-warning white--text font-weight-bold"
            ><span class="font-weight-regular" style="font-size: 10pt"
              >Attention: </span
            ><span>{{ Math.round(attentionScore) }}</span></v-btn
          >

          <v-tooltip
            v-if="
              heatmap.logo_tracking_status &&
              heatmap.logo_tracking_status === 'processing'
            "
            bottom
          >
            <template v-slot:activator="{ props }">
              <v-icon class="ml-2" v-bind="props" size="20"
                >mdi-information</v-icon
              >
            </template>

            <span
              >Brand attention analytics is being prepared for this video.</span
            >
          </v-tooltip>

          <v-icon
            small
            class="ml-2"
            v-if="
              heatmap.logo_tracking_status &&
              heatmap.logo_tracking_status === 'done'
            "
          >
            mdi-auto-fix
          </v-icon>

          <v-tooltip
            v-if="
              heatmap.logo_tracking_status &&
              heatmap.logo_tracking_status === 'requested' &&
              userProfile.superadmin
            "
            bottom
          >
            <template v-slot:activator="{ props }">
              <v-icon class="ml-2" color="error" v-bind="props" size="20"
                >mdi-alert</v-icon
              >
            </template>

            <span>Brand attention is requested for this video.</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-menu bottom left v-model="menu3">
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="heatmap.uid"
                prepend-icon="mdi-delete-outline"
                @click="$store.dispatch('deleteHeatmap', heatmap.uid)"
              >
                Delete heatmap
              </v-list-item>

              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ props }">
                  <v-list-item
                    prepend-icon="mdi-pencil-outline"
                    class="px-4"
                    v-bind="props"
                  >
                    Rename
                  </v-list-item>
                </template>

                <v-card>
                  <div class="pa-4">
                    <v-text-field
                      hide-details=""
                      v-model="newTitle"
                    ></v-text-field>
                  </div>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menu2 = false"> Cancel </v-btn>
                    <v-btn color="primary" text @click="updateTitle">
                      Update
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>

              <v-list-item
                v-if="!heatmap.sample && heatmap.uid"
                class="px-4"
                @click="$emit('addToProject', heatmap.uid)"
                prepend-icon="mdi-folder-outline"
              >
                Add to project
              </v-list-item>

              <v-menu bottom open-on-hover offset-x>
                <template v-slot:activator="{ props }">
                  <v-list-item
                    prepend-icon="mdi-fire"
                    class="px-4"
                    v-bind="props"
                  >
                    Download heatmap
                  </v-list-item>
                </template>

                <v-list>
                  <v-list-item
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click.stop="downloadHeatmap(1)"
                  >
                    Heatmap
                  </v-list-item>

                  <v-list-item
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click.stop="downloadHeatmap(2)"
                  >
                    Peekthrough
                  </v-list-item>

                  <v-list-item
                    v-if="heatmap.gazeplot"
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click.stop="downloadHeatmap(4)"
                  >
                    Gazeplot
                  </v-list-item>

                  <v-list-item
                    v-if="heatmap.logo_tracking_video"
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click.stop="downloadHeatmap(5)"
                  >
                    Brand Recognition
                  </v-list-item>

                  <v-list-item
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click.stop="downloadHeatmap(3)"
                  >
                    Original
                  </v-list-item>

                  <v-list-item
                    append-icon="mdi-download"
                    class="px-6 py-2"
                    @click="downloadAll"
                  >
                    Download all
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-list-item
                @click="restart"
                class="px-4"
                v-if="$store.state.users.userProfile.superadmin"
                prepend-icon="mdi-reload"
              >
                Re-generate
              </v-list-item>

              <v-list-item
                @click="restartFrames"
                class="px-4"
                prepend-icon="mdi-reload"
                v-if="$store.state.users.userProfile.superadmin"
              >
                Redo frames
              </v-list-item>

              <v-list-item
                @click="firebase(heatmap.uid)"
                class="px-4"
                prepend-icon="mdi-firebase"
                v-if="$store.state.users.userProfile.superadmin"
              >
                Firebase
              </v-list-item>
              <v-list-item
                @click="storage(heatmap.uid, heatmap.requested_by)"
                class="px-4"
                v-if="$store.state.users.userProfile.superadmin"
                prepend-icon="mdi-store"
              >
                Storage
              </v-list-item>

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    @click="$emit('transfer', heatmap.uid)"
                    class="px-4"
                    v-if="$store.state.users.userProfile.superadmin"
                    prepend-icon="mdi-account-arrow-right-outline"
                  >
                    Transfer
                  </v-list-item>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-autocomplete
                        class="mx-4 my-2"
                        label="Transfer heatmap to"
                        :items="users"
                        item-title="name"
                        hide-details=""
                        return-object
                        v-model="transferTo"
                      ></v-autocomplete>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text @click="menu = false"> Cancel </v-btn>
                    <v-btn color="primary" text @click="transfer">
                      Transfer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import moment from "moment";
import firebase from "firebase/app";
import { mapState } from "vuex";
import { db } from "@/firebase";
const axios = require("axios");

export default {
  props: ["heatmapProp"],
  emits: ["analyze", "share", "delete", "addToProject"],
  data() {
    return {
      heatmap: this.heatmapProp,
      menu: false,
      menu2: false,
      menu3: false,
      transferTo: null,
      newTitle: "",
    };
  },
  computed: {
    check: {
      get() {
        return this.$store.state.heatmaps.compare.includes(this.heatmap.uid);
      },
      set(value) {
        this.$store.commit("compare", { value: value, uid: this.heatmap.uid });
      },
    },
    projects() {
      const filtered = this.projects.filter((p) =>
        p.heatmaps.includes(this.heatmap.uid)
      );
      return filtered.length;
    },
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      projects: (state) => state.projects.projects,
      users: (state) => state.users.users,
      compare: (state) => state.heatmaps.compare,
    }),
    attentionScore() {
      let application = this.heatmap.application;
      let score;

      if (application === "Other" || application === "UX Design") {
        return null;
      } else if (application === "Display Ads") {
        if (this.heatmap.template_scores && this.heatmap.type === "image") {
          const dictionary = this.heatmap.template_scores.display;
          let sum = 0;
          let count = 0;
          Object.keys(dictionary).forEach(function (key) {
            count++;
            sum = sum + dictionary[key];
          });
          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        } else if (
          this.heatmap.application === "Display Ads" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });

          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        }
      } else if (application === "Social media") {
        if (this.heatmap.template_scores && this.heatmap.type === "image") {
          const dictionary = this.heatmap.template_scores.social;
          let sum = 0;
          let count = 0;
          Object.keys(dictionary).forEach(function (key) {
            count++;
            sum = sum + dictionary[key];
          });
          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        } else if (
          this.heatmap.application === "Social media" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        }
      } else if (application === "(Digital) Out Of Home") {
        if (this.heatmap.template_scores && this.heatmap.type === "image") {
          const dictionary = this.heatmap.template_scores.ooh;
          let sum = 0;
          let count = 0;
          Object.keys(dictionary).forEach(function (key) {
            count++;
            sum = sum + dictionary[key];
          });
          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        } else if (
          this.heatmap.application === "(Digital) Out Of Home" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          if (count > 0) {
            score = sum / count;
          } else {
            return null;
          }
        }
      }
      return score;
    },
  },
  mounted() {},
  methods: {
    checkbox(v) {
      console.log(v);
    },
    downloadAll() {
      this.downloadHeatmap(1);
      this.downloadHeatmap(2);
      this.downloadHeatmap(3);

      if (this.heatmap.gazeplot) {
        this.downloadHeatmap(4);
      }
      if (this.heatmap.logo_tracking_video) {
        this.downloadHeatmap(5);
      }
    },
    async downloadHeatmap(v) {
      const h = this.heatmap;
      var storage = firebase.storage();
      var storageRef = firebase.storage().ref();
      const storagePath = storageRef.child(`${h.requested_by}/${h.uid}`);
      var imageRef = storage.refFromURL(`${storagePath}`);

      if (h.type === "image") {
        let path = `${h.uid}.${h.extension}`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (this.variant === "Gazeplot") {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        } else {
          label = `${h.title}`;
        }

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.${h.extension}`;
        } else if (v === 4) {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        }

        const url = await imageRef.child(path).getDownloadURL();
        axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: "image/png" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label + ".png";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      } else {
        let path = `${h.uid}.mp4`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else {
          label = `${h.title}`;
        }

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.mp4`;
        } else if (v === 5) {
          label = `${h.title} - Brand Recognition`;
          path = `${h.uid}-output.mp4`;
        }

        const url = await imageRef.child(path).getDownloadURL();
        axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: "video/mp4" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label + ".mp4";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }
    },
    openInTab() {
      let url = `${window.location.origin}/heatmap/${this.heatmap.uid}`;
      window.open(url, "_blank");
      console.log(this.heatmap);
    },
    storage(uid, requester) {
      let production = window.location.origin.includes("brainsight");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "deepgaze-io" : "deepgaze-staging"
      }/storage/${
        production ? "deepgaze-io" : "deepgaze-staging"
      }.appspot.com/files/~2F${requester}~2F${uid}`;
      window.open(url, "_blank");
    },
    firebase(uid) {
      let production = window.location.origin.includes("brainsight");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "deepgaze-io" : "deepgaze-staging"
      }/firestore/data/~2Fheatmaps~2F${uid}`;
      window.open(url, "_blank");
    },
    getDate() {
      return moment(this.heatmap.created_at).format("DD-MM-YYYY");
    },

    async restart() {
      const vm = this;
      await db.collection("heatmaps").doc(vm.heatmap.uid).update({
        status: "requested",
      });

      if (vm.heatmap.type === "video") {
        var storageRef = firebase.storage().ref();
        storageRef
          .child(`${this.heatmap.requested_by}/${this.heatmap.uid}/frames`)
          .listAll()
          .then((res) => {
            res.items.forEach((itemRef) => {
              itemRef
                .delete()
                .then(() => {
                  // File deleted successfully
                })
                .catch((error) => {
                  // Uh-oh, an error occurred!
                });
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });

        await db.collection("heatmaps").doc(vm.heatmap.uid).update({
          attention_scores: [],
          frames_children: [],
          frames_requested: [],
          attention_score: null,
        });
      }

      var generate = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("generate");
      const res = await generate({ uid: vm.heatmap.uid });

      console.log(res);
    },
    async restartFrames() {
      const vm = this;

      if (vm.heatmap.type === "video") {
        await db.collection("heatmaps").doc(vm.heatmap.uid).update({
          attention_scores: [],
          status: "processed",
        });
      }

      var generate = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("regenerateFrames");
      const res = await generate({ uid: vm.heatmap.uid });
      console.log(res);
    },
    saveUpload() {
      this.heatmap.upload = false;
    },
    sendBack() {
      if (this.heatmap.status === "done") {
        this.$emit("analyze", this.heatmap.uid);
      }
    },
    async transfer() {
      var storageRef = firebase.storage().ref();
      var listRef = storageRef.child(
        `${this.heatmap.requested_by}/${this.heatmap.uid}`
      );
      var transfer = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("transfer");

      // Find all the prefixes and items.
      listRef
        .listAll()
        .then(async (res) => {
          for (let itemRef of res.items) {
            await transfer({
              dest: `${this.transferTo.uid}/${this.heatmap.uid}/${itemRef.name}`,
              origin: `${this.heatmap.requested_by}/${this.heatmap.uid}/${itemRef.name}`,
              name: itemRef.name,
            });
          }
        })
        .then(() => {
          db.collection("heatmaps")
            .doc(this.heatmap.uid)
            .update({
              workspace: this.transferTo.workspace,
              requested_by: this.transferTo.uid,
              requested_by_name: this.transferTo.name,
              requested_by_email: this.transferTo.email,
              transferred_from: this.userProfile.uid,
              status: "processed",
            })
            .then(() => {
              console.log("Heatmap transfered!");
              this.menu = false;
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    },
    updateTitle() {
      db.collection("heatmaps")
        .doc(this.heatmap.uid)
        .update({
          title: this.newTitle,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });

      this.menu3 = false;
      this.menu2 = false;
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
