import firebase from "firebase/";
import { db, auth } from "@/firebase";
import store from "@/store";
import router from "@/router/index";
import * as fb from "@/firebase";

const users = {
  state: {
    users: [],
    userProfile: {},
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setUsers(state, val) {
      state.users = val;
    },
  },
  actions: {
    async fetchUserProfile({ commit, dispatch, rootState }, user) {
      commit("setLoading", true);

      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      const fetchedUser = userProfile.data();
      var verified = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("verified");
      const verif = await verified({ email: user.email });

      commit("setUserProfile", fetchedUser);

      const allPromises = [];

      allPromises.push(
        dispatch("fetchHeatmaps"),
        dispatch("fetchProjects"),
        dispatch("fetchReports")
      );

      await Promise.all(allPromises);

      if (fetchedUser.role === "trial") {
        const date1 = fetchedUser.trialEnd.toDate();
        const date2 = new Date();
        if (date2 > date1) {
          fetchedUser.trialEnded = true;
        }
      }

      await db.collection("users").doc(fetchedUser.uid).update({
        last_login: new Date(),
      });

      // for members and admins:
      if (fetchedUser.workspace && user.email && user.emailVerified) {
        await dispatch("fetchWorkspace");
        dispatch("fetchUsers");
      }

      if (user.email && user.emailVerified) {
        await dispatch("fetchSubscription");
      }
      if (fetchedUser.superadmin) {
        dispatch("fetchModels");
        dispatch("fetchAdminWorkspaces");
        dispatch("fetchAdminUsers");
        dispatch("fetchAdminStats");
        dispatch("fetchAdminStripe");
        dispatch("fetchAPI");
      }

      commit("setLoading", false);
      dispatch("checkRequests");
      dispatch("fetchTutorials");

      // change route to dashboard
      if (verif.data != true) {
        router.push("/verify");
      } else if (
        !userProfile.data().workspace &&
        userProfile.data().role != "trial" &&
        userProfile.data().role != "freemium"
      ) {
        router.push("/add-workspace");
      } else if (
        !userProfile.data().workspace &&
        rootState.subscription.subscription.active
      ) {
        router.push("/add-workspace");
      } else if (
        router.currentRoute.value.name === "Signup" ||
        router.currentRoute.value.name === "Login"
      ) {
        if (
          router.currentRoute.value.name === "Signup" &&
          userProfile.data().role === "trial"
        ) {
          router.push("/followup");
        } else if (
          router.currentRoute.value.query.workspace &&
          router.currentRoute.value.query.workspace != fetchedUser.workspace
        ) {
          router.push(
            `/invited?workspace=${router.currentRoute.value.query.workspace}&workspaceName=${router.currentRoute.value.query.workspaceName}`
          );
        } else {
          router.push("/");
        }
      }
    },
    fetchUsers({ commit, state }) {
      return new Promise((resolve) => {
        let users = [];
        let userWorkspace = state.userProfile.workspace;

        if (userWorkspace && !state.userProfile.superadmin) {
          db.collection("users")
            .where("workspace", "==", String(userWorkspace))
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                if (!doc.data().deleted_at) {
                  users.push(doc.data());
                }
              });
            })
            .then(() => {
              if (users.length > 0) {
                users.sort((a, b) =>
                  a.name < b.name ? -1 : b.name < a.name ? 1 : 0
                );
              }
              commit("setUsers", users);
              resolve("Users loaded");
            })
            .catch((error) => {
              resolve("Error getting users from same workspace: ", error);
            });
        } else if (state.userProfile.superadmin) {
          db.collection("users")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                users.push(doc.data());
              });
            })
            .then(() => {
              if (users.length > 0) {
                users.sort((a, b) =>
                  a.name < b.name ? -1 : b.name < a.name ? 1 : 0
                );
              }
              commit("setUsers", users);
              resolve("Users loaded");
            })
            .catch((error) => {
              resolve("Error getting users from same workspace: ", error);
            });
        } else {
          console.log("no workspace to get users");
        }
      });
    },
    removeUser({ dispatch, state, rootState }, user) {
      return new Promise((resolve) => {
        // Remove user from workspace
        if (user.workspace) {
          db.collection("workspaces")
            .doc(state.userProfile.workspace)
            .update({
              members: firebase.firestore.FieldValue.arrayRemove(user.uid),
              seats: firebase.firestore.FieldValue.increment(1),
            });
        }

        // Set removed user to freemium with freemium credits.
        db.collection("users")
          .doc(user.uid)
          .update({
            workspace: "",
            role: "freemium",
            imageCredits: rootState.defaults.defaults.freemium.images,
            videoCredits: rootState.defaults.defaults.freemium.videos,
          })
          .then(async () => {
            if (user.workspace) {
              store.dispatch("fetchWorkspace");
              const users = await dispatch("fetchUsers");
              console.log(users);
            }
            store.dispatch("fetchUserProfile", auth.currentUser);
            console.log("User successfully removed!");
            if (!user.workspace) {
              resolve(true);
            }
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });

        // Heatmaps will stay in the workspace
        if (user.workspace) {
          db.collection("heatmaps")
            .where("requested_by", "==", user.uid)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                db.collection("heatmaps")
                  .doc(doc.id)
                  .update({
                    requested_by: "",
                  })
                  .then(() => {
                    console.log("Updated workspace field on heatmap");
                    resolve(true);
                  })
                  .catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                  });
              });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        }
      });
    },
  },
  getters: {},
};

export default users;
