<template>
  <v-sheet
    rounded
    v-if="
      (heatmap.clarity_score_percentile === 0 ||
        heatmap.clarity_score_percentile > 0) &&
      heatmap.application != 'Other'
    "
  >
    <v-list bg-color="accent" class="py-0" style="border-radius: 6px">
      <v-list-item link style="width: 100%">
        <div class="py-4" style="width: 100%">
          <div class="d-flex align-center">
            <h4 class="white--text font-weight-medium">Clarity score:</h4>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              icon="mdi-information"
              size="small"
              variant="text"
              @click="
                openLink(
                  'https://braingineers.gitbook.io/brainsight/metrics/clarity/about-the-clarity-score'
                )
              "
            ></v-btn>
          </div>

          <p class="mb-0">
            <span class="font-weight-bold white--text" style="font-size: 22pt"
              >{{ heatmap.clarity_score_percentile }}
              <span class="font-weight-regular" style="font-size: 15pt"
                >/100</span
              >
            </span>
          </p>

          <div class="" v-if="heatmap.application === 'UX Design'">
            <span
              v-if="heatmap.type === 'image'"
              class="white--text"
              style="font-size: 10pt"
              >{{ getCountry(heatmap.country) }}</span
            >
            <v-icon
              v-if="heatmap.type === 'image'"
              size="7"
              color="white"
              class="mx-1"
              >mdi-circle</v-icon
            >
            <span
              v-if="heatmap.type === 'image'"
              class="white--text"
              style="font-size: 10pt"
              >{{ getIndustry(heatmap.industry) }}</span
            >
          </div>
        </div>
      </v-list-item>

      <div v-if="heatmap.metrics && heatmap.metrics.percentile_area >= 0">
        <v-divider></v-divider>

        <AreaCard :heatmap="heatmap" />
        <SpreadCard :heatmap="heatmap" />
      </div>
    </v-list>
  </v-sheet>
</template>

<style lang="scss" scoped></style>

<script>
import AreaCard from "../focusCard/FocusCard.vue";
import SpreadCard from "../spreadCard/SpreadCard.vue";

export default {
  props: ["heatmap"],
  components: {
    AreaCard,
    SpreadCard,
  },
  computed: {},
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    getCountry(c) {
      let country = "";

      switch (c) {
        case "":
          country = "All countries";
          break;
        case "de":
          country = "Germany";
          break;
        case "nl":
          country = "The Netherlands";
          break;
        case "es":
          country = "Spain";
          break;
        case "uk":
          country = "United Kingdom";
          break;
        case "us":
          country = "United States";
          break;
      }
      console.log(country);
      return country;
    },
    getIndustry(i) {
      let industry = "";

      switch (i) {
        case "":
          industry = "All industries";
          break;
        case "arts and entertainment":
          industry = "Arts & Entertainment";
          break;
        case "vehicles":
          industry = "Automotive & vehicles";
          break;
        case "computers electronics and technology":
          industry = "Computers, electronics & technology";
          break;
        case "e-commerce and shopping":
          industry = "E-commerce & shopping";
          break;
        case "finance":
          industry = "Finance";
          break;
        case "food and beverages":
          industry = "Food & beverages";
          break;
        case "home and garden":
          industry = "Home & garden";
          break;
        case "lifestyle":
          industry = "Lifestyle";
          break;
        case "news and media":
          industry = "News & media";
          break;
        case "sports":
          industry = "Sports";
          break;
        case "travel":
          industry = "Travel";
          break;
      }
      return industry;
    },
  },
};
</script>
