<template>
  <div class="text-center">
    <v-dialog opacity="0.75" max-width="1000" v-model="dialog">
      <template v-slot:activator="{ props: activatorProps }">
        <v-tooltip text="Let Brainsight Co-Pilot analyse your design.">
          <template v-slot:activator="{ props }">
            <div v-bind="props">
              <v-btn v-bind="activatorProps" elevation="0" color="white" block>
                <v-badge content="beta" color="primary" floating>
                  <v-img
                    width="18"
                    :src="require('@/assets/logo-dark.svg')"
                  ></v-img>
                </v-badge>
              </v-btn>
            </div>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card
          style="
            width: 90%;
            font-size: 10pt;
            margin: auto;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          "
          class="px-2 pt-2 pb-4 bg-primary text-center"
        >
          <span>Currently in Beta</span></v-card
        >

        <v-card flat class="px-10 py-6" max-width="1000px">
          <div class="mt-4 mb-6 d-flex align-center justify-space-between">
            <div>
              <h2>Creative Quality Audit</h2>
              <p style="font-size: 10pt; max-width: 70%">
                An automated analysis on the quality of the creative by
                Brainsight Co-Pilot, based on your creative and the heatmap data
                on top, predicting the creative's impact in the most crucial
                first 5 seconds of attention.
              </p>
            </div>
            <div>
              <v-btn
                icon="mdi-close"
                @click="isActive.value = false"
                variant="tonal"
                size="small"
                v-if="heatmap && heatmap.audit"
              ></v-btn>
              <div v-else>
                <v-btn
                  variant="tonal"
                  size="small"
                  :loading="processing"
                  @click="request"
                  >Request Analysis</v-btn
                >
              </div>
            </div>
          </div>

          <div v-if="heatmap.audit">
            <div>
              <v-divider></v-divider>
              <h3 class="mt-10">1. Associations</h3>
              <p class="mb-4" style="font-size: 10pt">
                Associations related to this page after looking for maximum of 5
                seconds.
              </p>
              <div class="d-flex flex-wrap" style="gap: 10px">
                <v-chip
                  size="large"
                  color="primary"
                  v-for="(i, index) in heatmap.audit.associations"
                  :key="index"
                  >{{ i.association }}</v-chip
                >
              </div>
            </div>
            <v-divider class="my-10"></v-divider>

            <h3 class="mt-6">2. Clarity</h3>
            <p class="mb-4" style="font-size: 10pt">
              Benchmarked metrics to determine the cognitive ease for this
              website.
            </p>
            <div
              style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px"
            >
              <div>
                <v-card class="pa-6 bg-accent">
                  <div class="d-flex">
                    <div>
                      <p class="mb-1">Focus</p>
                      <p style="font-size: 10pt">
                        Focus indicates the relative size and amount of the
                        attention grabbing elements.
                      </p>
                    </div>
                    <v-progress-circular
                      :model-value="100 - heatmap.metrics.percentile_area"
                      :size="100"
                      :width="11"
                      :color="getColor(100 - heatmap.metrics.percentile_area)"
                    >
                      <template v-slot:default>
                        <b> {{ 100 - heatmap.metrics.percentile_area }}</b>
                      </template>
                    </v-progress-circular>
                  </div>

                  <v-img :src="heatmap.peekthrough"></v-img>
                </v-card>
                <v-card
                  class="pa-4 bg-smoke"
                  flat
                  style="
                    font-size: 10pt;

                    z-index: -1;
                    width: 95%;
                    margin: auto;
                  "
                >
                  <p v-if="100 - heatmap.metrics.percentile_area >= 65">
                    The Focus score is already better than our target (65 or
                    higher). Therefore there are no suggestions.
                  </p>
                  <div v-else>
                    <p>
                      There are multiple elements that grab attention, and of
                      which some are relatively big.
                    </p>
                    <p class="mt-2">
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i>
                        Consider to reduce the amount of attention grabbing
                        elements, specifically elements that are distracting
                        from the user's goal.</i
                      >
                    </p>
                  </div>
                </v-card>
              </div>

              <div>
                <v-card class="pa-6 bg-accent">
                  <div class="d-flex">
                    <div>
                      <p class="mb-1">Spread</p>
                      <p style="font-size: 10pt">
                        Spread indicates the distance between attention grabbing
                        elements within the creative.
                      </p>
                    </div>
                    <v-progress-circular
                      :model-value="heatmap.metrics.percentile_distances"
                      :size="100"
                      :width="11"
                      :color="
                        getColor(100 - heatmap.metrics.percentile_distances)
                      "
                    >
                      <template v-slot:default>
                        <b> {{ heatmap.metrics.percentile_distances }}</b>
                      </template>
                    </v-progress-circular>
                  </div>
                  <v-img :src="heatmap.gazeplot"></v-img>
                </v-card>

                <v-card
                  class="pa-4 bg-smoke"
                  flat
                  style="
                    font-size: 10pt;

                    z-index: -1;
                    width: 95%;
                    margin: auto;
                  "
                >
                  <p v-if="heatmap.metrics.percentile_distances <= 35">
                    The Spread score is already better than our target (35 or
                    lower). Therefore there are no suggestions.
                  </p>

                  <div v-else>
                    <p>
                      There are multiple attention grabbing elements and they
                      are distant from each other.
                    </p>
                    <p class="mt-2">
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i>
                        Consider to move the attention grabbing elements closer,
                        and/or remove distracting elements.</i
                      >
                    </p>
                  </div>
                </v-card>
              </div>
            </div>

            <v-divider class="my-10"></v-divider>
            <div>
              <h3 class="mt-10">4. Attention Score</h3>
              <p class="mb-4" style="font-size: 10pt">
                The predicted attention towards this creative, when it it shown
                in an average {{ heatmap.application }} situation.
              </p>

              <div
                class="my-10"
                style="display: grid; grid-template-columns: 1fr 1fr"
              >
                <div class="d-flex align-center" style="width: 100%">
                  <AttentionGraph
                    style="width: 100%"
                    :attention_score="attention_score"
                  />
                </div>
                <div class="d-flex align-center justify-center">
                  <v-card style="font-size: 10pt" flat class="pa-4 bg-smoke">
                    <p>
                      <v-icon size="16" class="mr-2">mdi-arrow-left</v-icon>
                      When shown in a {{ heatmap.application }} situational
                      context, your ad needs to stand out visually. We've
                      compared your ad to our benchmark in terms of saliency.
                      Your ad's saliency is
                      <b v-if="attention_score > 64">high</b
                      ><b v-else-if="attention_score > 34">average</b
                      ><b v-else>low</b> compared to our benchmark and is likely
                      to convert
                      {{
                        attention_score > 64
                          ? "better than average"
                          : attention_score > 34
                            ? "average"
                            : "lower than average"
                      }}.
                    </p>
                    <p class="mt-2" v-if="attention_score < 65">
                      <v-icon size="16" class="mr-2">mdi-lightbulb</v-icon
                      ><i>
                        <u v-if="attention_score < 35"
                          >Your ad does not stand out.</u
                        ><u v-else>There is room for improvement.</u> To improve
                        an ad’s saliency in {{ heatmap.application }} context,
                        use bold, contrasting colors and high-quality images or
                        videos to make it visually stand out. Including human
                        faces with emotional expressions can create a stronger
                        connection and increase engagement.</i
                      >
                    </p>
                  </v-card>
                </div>
              </div>
            </div>

            <v-divider class="my-10"></v-divider>
            <h3 class="mt-6">4. Creative Quality Scores</h3>
            <p class="mb-4" style="font-size: 10pt; max-width: 70%">
              Based on scaled questions to valuate the key components of a
              website. Remember, they are answered based on the first 5 seconds.
            </p>
            <div
              style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px"
              class="mt-6"
            >
              <v-card
                flat
                class="pa-4"
                style="display: grid; grid-template-columns: 100px auto"
              >
                <div class="pr-4">
                  <p>
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.message.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.message.rating * 10)"
                    :model-value="heatmap.audit.message.rating * 10"
                    rounded
                  ></v-progress-linear>
                </div>
                <div>
                  <div class="mb-1 d-flex align-center">
                    <span><b>Message</b></span>
                    <v-tooltip text="Tooltip">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          color="grey"
                          class="ml-1"
                          size="16"
                          v-bind="props"
                          >mdi-information</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </div>

                  <p style="font-size: 10pt">
                    {{ heatmap.audit.message.reasoning }}
                  </p>

                  <div
                    style="position: relative"
                    v-if="heatmap.audit.message.rating <= 7"
                  >
                    <v-card
                      flat
                      class="mt-2 bg-smoke pa-2"
                      style="font-size: 10pt"
                    >
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i> {{ heatmap.audit.message.suggestion }}</i>
                    </v-card>
                    <div
                      style="
                        position: absolute;
                        left: 10px;
                        top: -5px;
                        z-index: 99;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #ebebef;
                      "
                    ></div>
                  </div>
                </div>
              </v-card>

              <v-card
                flat
                class="pa-4"
                style="display: grid; grid-template-columns: 100px auto"
              >
                <div class="pr-4">
                  <p>
                    <span style="font-size: 30pt; font-weight: 600">
                      {{ heatmap.audit.brand.rating }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.brand.rating * 10)"
                    :model-value="heatmap.audit.brand.rating * 10"
                    rounded
                  ></v-progress-linear>
                </div>
                <div>
                  <div class="mb-1 d-flex align-center">
                    <span><b>Brand</b></span>
                    <v-tooltip text="Tooltip">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          color="grey"
                          class="ml-1"
                          size="16"
                          v-bind="props"
                          >mdi-information</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </div>

                  <p style="font-size: 10pt">
                    {{ heatmap.audit.brand.reasoning }}
                  </p>

                  <div
                    style="position: relative"
                    v-if="heatmap.audit.brand.rating <= 7"
                  >
                    <v-card
                      flat
                      class="mt-2 bg-smoke pa-2"
                      style="font-size: 10pt"
                    >
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i> {{ heatmap.audit.brand.suggestion }}</i>
                    </v-card>
                    <div
                      style="
                        position: absolute;
                        left: 10px;
                        top: -5px;
                        z-index: 99;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #ebebef;
                      "
                    ></div>
                  </div>
                </div>
              </v-card>

              <v-card
                flat
                class="pa-4"
                style="display: grid; grid-template-columns: 100px auto"
              >
                <div class="pr-4">
                  <p>
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.product.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.product.rating * 10)"
                    :model-value="heatmap.audit.product.rating * 10"
                    rounded
                  ></v-progress-linear>
                </div>
                <div>
                  <div class="mb-1 d-flex align-center">
                    <span><b>Product or service</b></span>
                    <v-tooltip text="Tooltip">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          color="grey"
                          class="ml-1"
                          size="16"
                          v-bind="props"
                          >mdi-information</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </div>

                  <p style="font-size: 10pt">
                    {{ heatmap.audit.product.reasoning }}
                  </p>

                  <div
                    style="position: relative"
                    v-if="heatmap.audit.product.rating <= 7"
                  >
                    <v-card
                      flat
                      class="mt-2 bg-smoke pa-2"
                      style="font-size: 10pt"
                    >
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i> {{ heatmap.audit.product.suggestion }}</i>
                    </v-card>
                    <div
                      style="
                        position: absolute;
                        left: 10px;
                        top: -5px;
                        z-index: 99;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #ebebef;
                      "
                    ></div>
                  </div>
                </div>
              </v-card>

              <v-card
                flat
                class="pa-4"
                style="display: grid; grid-template-columns: 100px auto"
              >
                <div class="pr-4">
                  <p>
                    <span style="font-size: 30pt; font-weight: 600">{{
                      heatmap.audit.conversion.rating
                    }}</span
                    ><span>/10</span>
                  </p>
                  <v-progress-linear
                    :color="getColor(heatmap.audit.conversion.rating * 10)"
                    :model-value="heatmap.audit.conversion.rating * 10"
                    rounded
                  ></v-progress-linear>
                </div>
                <div>
                  <div class="mb-1 d-flex align-center">
                    <span><b>Conversion</b></span>
                    <v-tooltip text="Tooltip">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          color="grey"
                          class="ml-1"
                          size="16"
                          v-bind="props"
                          >mdi-information</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </div>

                  <p style="font-size: 10pt">
                    {{ heatmap.audit.conversion.reasoning }}
                  </p>

                  <div
                    style="position: relative"
                    v-if="heatmap.audit.conversion.rating <= 7"
                  >
                    <v-card
                      flat
                      class="mt-2 bg-smoke pa-2"
                      style="font-size: 10pt"
                    >
                      <v-icon size="14" class="mr-1">mdi-lightbulb</v-icon>
                      <i> {{ heatmap.audit.conversion.suggestion }}</i>
                    </v-card>
                    <div
                      style="
                        position: absolute;
                        left: 10px;
                        top: -5px;
                        z-index: 99;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #ebebef;
                      "
                    ></div>
                  </div>
                </div>
              </v-card>
            </div>
            <v-divider class="mt-10"></v-divider>
            <div class="my-10 d-flex justify-center">
              <div style="width: 400px">
                <div class="d-flex justify-space-between align-center mb-2">
                  <img
                    class="mb-2"
                    src="@/assets/logo-dark.svg"
                    style="width: 30px"
                    alt=""
                  />
                  <span
                    >Help us improve this
                    <v-chip color="primary" variant="flat" class="mx-1">
                      beta</v-chip
                    >
                    feature</span
                  >
                </div>

                <v-textarea
                  variant="solo"
                  flat
                  bg-color="smoke"
                  label="Feedback"
                  hide-details=""
                  class="mb-2"
                  v-model="feedback"
                ></v-textarea>
                <v-btn
                  :disabled="!feedback"
                  color="primary"
                  flat
                  variant="tonal"
                  block=""
                  @click="sendFeedback"
                  >Send feedback</v-btn
                >
                <div v-if="feedback_sent">Your feedback is sent. Thanks!</div>
              </div>
            </div>
          </div>

          <v-btn
            color="primary"
            text="Close"
            variant="flat"
            @click="isActive.value = false"
          ></v-btn>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import AttentionGraph from "./AttentionGraph.vue";
import { db } from "@/firebase";

export default {
  components: {
    AttentionGraph,
  },
  props: {
    heatmap: {
      type: Object,
      default: null,
    },
    attention_score: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      feedback: "",
      feedback_sent: false,
      processing: false,
      show: false,
    };
  },
  methods: {
    getColor(value) {
      if (value < 40) {
        return "error";
      } else if (value < 60) {
        return "info";
      } else {
        return "success";
      }
    },
    async request() {
      this.processing = true;
      var auditRequestCreative = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("auditRequestCreative");
      const res = await auditRequestCreative(this.heatmap.uid);
      console.log(res);
      this.$emit("ready");
      this.processing = false;
    },
    async sendFeedback() {
      const doc = await db.collection("mail").add({
        to: "support@brainsight.app",
        from: "Brainsight <info@brainsight.app>",
        message: {
          subject: "Beta feedback - Creative Quality Audit",
          html: `${this.$store.state.users.userProfile.email} - ${this.feedback}`,
        },
      });
      console.log(doc.id);
      this.feedback_sent = true;
    },
  },
};
</script>
